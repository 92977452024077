
import { useState, useEffect } from 'react'
import dynamic from "next/dynamic";
const HomePage = dynamic(() => import('./home_page/landing_page'))
const HomePageMobile = dynamic(() => import('./home_page/landing_page_mobile'))
//import HomePage from './home_page/landing_page'
//import HomePageMobile from './home_page/landing_page_mobile'
import Layout from '../components/Layout'
import config_backend from '../config/config_backend.json';
import config_frontend from '../config/config_frontend.json';

import request from '../services/request'
import isCrawler from "../actions/data";
import { checkSession } from '../services/newAuth'
var useragent = require('express-useragent');

export default function Home(props) {
    const [loggedin_info, setloggedin_info] = useState()
    const [isMobile, setIsMobile] = useState(props.isMobile);

    useEffect(() => {
        if (isMobile && window.screen.availWidth >= 1024) {
            setIsMobile(false);
        }
        window.addEventListener('resize', onScreenResize);
        checkSession()
            .then(data => {
                console.log("check session data", data)
                localStorage.removeItem("selectedfilter");
                localStorage.removeItem("currentFilter");
                setloggedin_info(data)
                try {
                    var mop = config_frontend.isRedeemJourney ? new URL(window.location.href).searchParams.get("mop") : 'accrual';
                    if (window.clevertapData) {
                        window.clevertapData["Global"] = {
                            'Channel': props.isMobile ? 'Mweb' : 'Web',//'<App/Web/Webview/Mweb>', 
                            'Property': 'Interbook',
                            'Category': (mop == null || mop == undefined || mop == "") ? "None" : mop == "accrual" ? "Earn" : "Redeem", //<Earn/Redeem/None>
                            'Page Name': (window.location.host + ((window.location.pathname == "/") ? "" : window.location.pathname)).replace(/\//g, ":"),
                            'CleverTap ID': '',
                            'Login Status': data !== null ? "Logged In" : "Anonymous"
                        }
                    }
                } catch (e) {
                    console.log("ct err:", e)
                }
            })
            .catch(err => console.log("check session error", err))
    }, [isMobile])

    function onScreenResize() {
        setIsMobile(window.screen.availWidth <= 1023);
    }

    return (
        <Layout
            title={config_frontend.page_seo.home[props.pageType].title}
            meta={config_frontend.page_seo.home[props.pageType].meta}
            description={config_frontend.page_seo.home[props.pageType].description}
            keywords={config_frontend.page_seo.home[props.pageType].keywords}
            canonical={config_frontend.page_seo.home[props.pageType].canonical}
        >
            {!isMobile ?
                <HomePage
                    loggedIn_Info={loggedin_info}
                    homepageData={props.homepageData}
                    recommendationData={props.recommendationData}
                    specialDeals={props.specialDeals}
                    titles={props.titles}
                    iscrawler={props.iscrawler}
                    pageType={props.pageType}
                    mop={props.mop}
                ></HomePage>
                :
                <HomePageMobile
                    loggedIn_Info={loggedin_info}
                    homepageData={props.homepageData}
                    recommendationData={props.recommendationData}
                    specialDeals={props.specialDeals}
                    titles={props.titles}
                    iscrawler={props.iscrawler}
                    pageType={props.pageType}
                    mop={props.mop}
                ></HomePageMobile>
            }
        </Layout>
    )
}


export async function getServerSideProps({ req, query, params }) {
    let mop = config_frontend.isRedeemJourney && query.mop == "redeem" ? "redeem" : "accrual";
    // var userdetails = await UserService.fetchUser(req.cookies);
    let iscrawler = isCrawler.detectCrawler(req.headers['user-agent']);
    //let iscrawler = false; // We Have set it for server side rendering, once tested this line will be removed
    let source = req.headers['user-agent'];
    let ua = useragent.parse(source);

    //let isMobile = ua.isMobile ? (ua.isiPad ? false : true) : false;
    let isMobile = ua.isMobile
    let pageUrl = req.url
    // console.log("pageUrl:," , pageUrl)
    let pageType = 'domestic';
    if (pageUrl.includes('international-hotels')) {
        pageType = 'international'
    }

    // console.log('Page Type', pageType)
    if (iscrawler) {
        // console.log("Vijay Here")
        var specialDealsResult;
        var homepageDataResult;
        var recommendationResult;


        async function spdealapi() {
            let body = { type: mop }
            let url = `${config_backend.simplica.baseurl}v3/sepcialdeal`;
            req.moduleName = 'Special Deals';
            let special_deals_data = await request.get(req, url, body);

            if (special_deals_data.status) {
                return { ...special_deals_data.data, key: 'specialDeals' }
            }
            let data = {
                status: false, statuscode: 301,
                message: "something wrong! Please try again",
                data: {}
            }
            return { ...data, key: 'specialDeals' }
        }

        async function homepgapi() {
            let queryParams = { "member_number": null, "getKey": "" }
            let url = `${config_backend.simplica.baseurl}v3/gethomepge`
            req.moduleName = 'Home Page Details';
            let home_data = await request.get(req, url, queryParams);

            if (home_data.status) {
                return { ...home_data.data, key: 'homepageData' }
            }
            let data = {
                status: false, statuscode: 301,
                message: "something wrong! Please try again",
                data: {}
            }
            return { ...data, key: 'homepageData' }
        }

        async function recomdapi() {
            let body = {
                hotelids: '',
                type: mop,
                tier: 'base' // Hardcoded it will be changed if user is logged in in get call below
            }

            let url = `${config_backend.simplica.baseurl}v3/recommandation`

            req.moduleName = 'Recommendation';
            let recommendationdata = await request.get(req, url, body);

            if (recommendationdata.status) {
                return { ...recommendationdata.data, key: 'recommendation' }
            }
            let data = {
                status: false, statuscode: 301,
                message: "something wrong! Please try again",
                data: {}
            }
            return { ...data, key: 'recommendation' }
        }

        var mainRe = await Promise.all([spdealapi(), homepgapi(), recomdapi()]).then(results => { return results });

        specialDealsResult = mainRe.find(item => item.key == 'specialDeals');
        homepageDataResult = mainRe.find(item => item.key == 'homepageData');
        recommendationResult = mainRe.find(item => item.key == 'recommendation');



        //console.log("homepageDat",JSON.stringify(homepageDataResult));
        let titles = (homepageDataResult?.data['titles']) ? homepageDataResult?.data['titles'][0] : []
        return {
            props: {
                homepageData: homepageDataResult.data,
                specialDeals: specialDealsResult.data,
                recommendationData: recommendationResult.data,
                titles: titles,
                iscrawler: iscrawler,
                isMobile: isMobile,
                pageType: pageType,
                mop: mop
            }
        }
    }
    else {
        let queryParams = { "member_number": null, "getKey": "" }
        let url = `${config_backend.simplica.baseurl}v3/gethomepge`
        req.moduleName = 'Home Page Details';
        homepageDataResult = await request.get(req, url, queryParams);
        let titles = (homepageDataResult?.data?.data['titles']) ? homepageDataResult?.data?.data['titles'][0] : []
        return {
            props: {
                homepageData: homepageDataResult.data.data ? homepageDataResult.data.data : [],
                specialDeals: [],
                recommendationData: [],
                titles: titles,
                iscrawler: iscrawler,
                isMobile: isMobile,
                pageType: pageType,
                mop: mop
            }
        }
    }
}