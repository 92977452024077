import Head from "next/head"

export default function Layout(props) {
    // console.log("routersss",router)

    const { title, description, keywords, meta, canonical, nofollow, hideHeader, children } = props
    return (
        <>
            <Head>
                <title>{title}</title>
                {description ? <meta name="description" content={description}></meta> : ""}
                {keywords ? <meta name="keywords" content={keywords}></meta> : ""}
                {
                    (meta) && meta.map((value, index) => {
                        return (
                            <meta key={index} name={value.property} content={value.content}></meta>
                        )
                    })
                }
                {canonical ? <link rel="canonical" href={canonical} /> : ""}
                {nofollow ? <link rel="nofollow" href={nofollow} /> : ""}
            </Head>
            <div id="header" className="jp-react-cmp" style={{ display: hideHeader ? 'none' : 'block' }}>
            </div>
            <div id="root" className="jp-react-cmp">
            </div>
            <div className="jp-react-cmp">
                {children}
            </div>
            <div id="footer" className="jp-react-cmp" style={{ display: hideHeader ? 'none' : 'block' }}>
            </div>
        </>
    )

}